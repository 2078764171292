import { Settings } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Paths } from "../../paths";
import { WouterIconButton } from "../WouterLink";

export const Footer = () => {
  return (
    <Box p={2} position="relative">
      <Typography variant="body2" color="text.secondary" align="center">
        {`© ${new Date().getFullYear()} Entrust Corporation. All rights reserved.`}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        This website is for demo purposes only. It is not an actual bank. This is not an official Entrust product.
      </Typography>

      <WouterIconButton href={Paths.settings} sx={{ position: "absolute", bottom: 0, right: 0 }}>
        <Settings />
      </WouterIconButton>
    </Box>
  );
};
