import { AccountBalance, School } from "@mui/icons-material";
import { AppBar, Grid2, Stack, Toolbar, Typography } from "@mui/material";

import { useAuth } from "../../hooks/useAuth";
import ProfileMenu from "../ProfileMenu";

export const AppHeader = () => {
  const { theme, isUniversityTheme } = useAuth();

  return (
    <AppBar position="fixed">
      <Toolbar>
        <Grid2 size={6} flex={1}>
          <Stack direction="row" spacing={2} alignItems="center">
            {isUniversityTheme() ? <School /> : <AccountBalance />}
            <Typography sx={{ display: { xs: "none", sm: "block" } }} variant="h6">
              {theme.name}
            </Typography>
          </Stack>
        </Grid2>

        <Grid2 size={6}>
          <ProfileMenu />
        </Grid2>
      </Toolbar>
    </AppBar>
  );
};
