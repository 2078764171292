import { Component, type ReactNode } from "react";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface State {
  error: Error | null;
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  state: State = {
    error: null,
  };

  componentDidCatch(error: Error) {
    console.error("💣 ", error);
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  render() {
    const { error } = this.state;

    if (error) {
      return <ErrorPage message={error.message} stacktrace={error.stack} />;
    }

    return this.props.children;
  }
}

interface Props {
  message: string;
  stacktrace?: string;
}

function ErrorPage({ message, stacktrace }: Props) {
  const retry = () => {
    window.location.pathname = "/";
  };

  return (
    <div>
      <div
        style={{
          marginTop: 64,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h5>Error</h5>

        <p>{message}</p>

        {stacktrace && (
          <div>
            <details>
              <div style={{ fontFamily: "monospace" }}>{stacktrace}</div>
            </details>
          </div>
        )}

        <button id="app-error-reload" onClick={retry} type="button">
          Refresh
        </button>
      </div>
    </div>
  );
}
