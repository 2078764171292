export const Paths = {
  account: "/account/:accountType",
  home: "/",
  login: "/login",
  logout: "/logout",
  register: "/register",
  settings: "/settings",
  profile: "/profile",
  studentHome: "/student",
  oidcLogin: "/oidc",
  algoan: "/algoan/:cardType",
  logindefault: "/logindefault",
  loginpassword: "/loginpassword",
};
