import { Box, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import type { ReactNode } from "react";
import { useLocation } from "wouter";
import { Paths } from "../../paths";
import { AppHeader } from "./AppHeader";
import { Footer } from "./Footer";

interface Props {
  children: ReactNode;
}

const Layout = ({ children }: Props) => {
  const [location] = useLocation();

  const showHeader = ![Paths.login, Paths.register, Paths.oidcLogin].includes(location);

  return (
    <Box sx={{ display: "flex" }}>
      {/* App Header Bar */}

      {showHeader && <AppHeader />}

      {/* App Body */}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          bgcolor: grey[200],
        }}
      >
        {showHeader && <DrawerHeader />}
        <Box flexGrow={1}>{children}</Box>

        {/* App Footer */}
        <Box>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default Layout;
