import type React from "react";
import { Redirect } from "wouter";
import { useAuth } from "../hooks/useAuth";
import { Paths } from "../paths";

interface Props {
  children?: React.ReactNode;
}

export const RequireNoAuth = ({ children }: Props) => {
  const { isLoggedIn, isUniversityTheme } = useAuth();

  return isLoggedIn() ? <Redirect to={isUniversityTheme() ? Paths.studentHome : Paths.home} replace /> : children;
};
