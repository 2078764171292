import { proxy } from "valtio";

type MessageSeverity = "success" | "info" | "warning" | "error";

export interface Message {
  /**
   * Whether or not the message is an error.
   */
  severity: MessageSeverity;

  /**
   * The message to display
   */
  message: string;

  /**
   * Optional action button text to show in the snackbar
   */
  action?: string;

  /**
   * Optional URL to redirect the user to when the click the action button
   */
  redirectUrl?: string;

  timestamp: number;
}

interface MessageStore {
  /**
   * Message FIFO queue
   */
  messages: Message[];

  /**
   * Add a message to the queue
   * @param message the message to add
   */
  // enqueue: (message: Message) => void;

  addMessage: (message: string, severity: MessageSeverity) => void;

  /**
   * Add a message to the queue
   * @param message the message to add
   */
  addSuccess: (message: string) => void;

  /**
   * Add an error message to the queue
   * @param message the error message to add
   */
  addError: (message: string) => void;

  /**
   * Add an info message to the queue
   * @param message the error message to add
   */
  addInfo: (message: string) => void;

  /**
   * Add a warning message to the queue
   * @param message the error message to add
   */
  addWarning: (message: string) => void;

  nextMessage: Message | null;
  closeMessage: () => void;
}

export const messageStore: MessageStore = proxy<MessageStore>({
  messages: [],
  addMessage: (message, severity: MessageSeverity) => {
    messageStore.messages.push({
      message,
      severity,
      timestamp: Date.now(),
    });
  },
  addSuccess: (message: string) => {
    messageStore.addMessage(message, "success");
  },
  addError: (message) => {
    messageStore.addMessage(message, "error");
  },
  addInfo: (message) => {
    messageStore.addMessage(message, "info");
  },
  addWarning: (message) => {
    messageStore.addMessage(message, "warning");
  },
  get nextMessage() {
    return this.messages[0] ?? null;
  },
  closeMessage: () => {
    messageStore.messages.shift();
  },
});

export const errorHandler = (e: Error | string) => {
  if (e instanceof Error) {
    messageStore.addError(e.message);
  } else {
    messageStore.addError(e);
  }
};

export const successHandler = (msg: string) => {
  messageStore.addSuccess(msg);
};

export const infoHandler = (msg: string) => {
  messageStore.addInfo(msg);
};

export const warningHandler = (msg: string) => {
  messageStore.addWarning(msg);
};
