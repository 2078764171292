import { Button, type ButtonProps, IconButton, type IconButtonProps, Link, type LinkProps } from "@mui/material";
import { Link as RouterLink } from "wouter";

export const WouterLink = (props: LinkProps) => <Link component={RouterLink} {...props} />;

export const WouterButton = (props: ButtonProps) => <Button component={RouterLink} {...props} />;

export const WouterIconButton = (props: IconButtonProps & Pick<ButtonProps, "href">) => (
  <IconButton LinkComponent={RouterLink} {...props} />
);
